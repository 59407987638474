<template>
  <div class="grid formgrid p-fluid">
    <div class="col-12">
      <Loader v-model="loading" />
        <Panel header="Configuracion de Productos">
          <TabView @tab-change="onTabChange" :activeIndex="tab">
            <TabPanel header="Productos">
               <ProductoUI ref="producto"/>
            </TabPanel>
            <TabPanel header="Familia">
                <FamiliaUI ref="familia" />
            </TabPanel>
            <TabPanel header="Lista de Precios">
                <ListaPrecioUI ref="listadeprecio" />
            </TabPanel>
        </TabView>
        </Panel>
    </div>
  </div>
</template>
<script>
import FamiliaUI from "./Productos/Familia.vue";
import ProductoUI from "./Productos/Producto.vue";
import ListaPrecioUI from "./Productos/ListaPrecio.vue";

import Loader from "../../components/general/Loader.vue";
export default {
  components: { FamiliaUI, ListaPrecioUI,  ProductoUI, Loader },
  data() {
    return {
        tab:0,
        loading: false
    };
  },
  methods: {
    onTabChange(payload) {
      switch (payload.index) {
        case 0:
          this.$refs.producto.refresh();
          break;
        case 1:
          this.$refs.familia.refresh();
          break;
        case 2:
          this.$refs.listadeprecio.refresh(); 
        break;
        default:
          break;
      }
    },
  },
  mounted() {
    this.$refs.producto.refresh();
  }
};
</script>

<style>
</style>

