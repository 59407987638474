<template>
  <div class="grid formgrid p-fluid">
    <div class="col-12">
        <Loader v-model="loading" />
        <Panel header="Familia">
          <div class="formgrid grid p-fluid">
          <div class="col-12">
              <FormToolbar :actions="['new','save','delete']" @new="newFamily" @save="saveFamily" @delete="deleteFamily" />
          </div>
          <FormInputText wrapperClass="field col-12 xl:col-4" label="Clave" v-model="family.key_name" />
          <FormInputText wrapperClass="field col-12 xl:col-8" label="Nombre" v-model="family.name" />
        </div>
        </Panel>
    </div>
    <div class="col-12">
        <BasicDatatable :rows="families" :selectionMode="'single'" :headers="headers" @selected="selectFamily"/>
    </div>
  </div>
</template>
<script>
import FormToolbar from "../../../components/general/BasicFormToolbar.vue";
import FormInputText from "../../../components/general/FormInputText.vue";
import formMixin from '../../../mixins/form.js';
import { family } from "../../../models/family";
import Loader from "../../../components/general/Loader.vue";
import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import { fillObject, HeaderGrid } from '../../../utilities/General';
export default {
  mixins: [formMixin],
    expose: ["refresh"],
  components: { FormInputText, FormToolbar, Loader, BasicDatatable },
  data() {
    return {
      loading: false,
      family: new family(),
      families: [],
      headers: [
        new HeaderGrid("Clave", "key_name"),
        new HeaderGrid("Nombre", "name")
      ]
    };
  },
  methods: {
    async refresh() {
        this.loading = true;
        this.family = new family(this.session);
        try {
        this.families = await this.family.all();
        } catch (error) {
          this.showError(error);
        } finally {
        this.loading = false;
        }
    },
    selectFamily(entity) {
      this.family = fillObject(this.family, entity);
    },
    newFamily() {
      this.family = new family(this.session);
    },
    async saveFamily() {
      this.loading = true;
      try {
        var newFamily = await this.family.save();
        this.families.push(newFamily);
        this.newFamily();
      } catch (error) {
        this.showError(error);
      } finally {
        this.loading = false;
      }
    },
  }
};
</script>

<style>
</style>

