export class price_list_product
{
    constructor(session) {
        this.user = session ? session.user : null;
        this.id_company = session ? session.id_company : null;
        this.id_branch = session ? session.id_branch : null;
        this.token = session ? session.token : null;
        this.id = null;
        this.id_pos_price_list = null;
        this.id_pos_product = null;
        this.price = null;
        this.created_by = null;
        this.created = null;
        this.modified_by = null;
        this.modified = null;
    }
}